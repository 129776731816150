import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import {
	Swiper,
	type TSwiperProps,
	type TSwiperRefActions,
} from '@headout/espeon/components';

import Conditional from 'Components/common/conditional';
import DummyProductCard from 'Components/common/dummyProductCard';
import FeedSectionHeader from 'Components/common/feedSectionHeader';
import ProductCardComponent from 'Components/common/productCardComponent';
import NavigationButtons from 'Components/desktop/navigationButtons';

import { useSwiperArrows } from 'Hooks/useSwiperArrows';
import { trackEvent } from 'Utils/analytics';
import { createSanitisedIdentifer } from 'Utils/gen';
import {
	getCurrentCityCode,
	getProductCard,
	getRecentlyViewedProductIds,
} from 'Utils/stateUtils';
import { capitalizeOnlyFirstLetter } from 'Utils/stringUtils';

import { fetchRecentProductIdList } from 'Thunks/productList';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { GLOBAL_CITY_CODE } from 'Constants/constants';

const ListWrapper = styled.div`
	width: 75rem;
	margin-top: 1.25rem;
`;
type Props = {
	onScrollIntoView: any;
	className?: string;
	elementsToShow?: number;
	elementsToSlide?: number;
	lang?: string;
	title: string;
	currentCityCode: string;
	productIds: any[];
	fullWidthCards?: boolean;
	fetchProductIdListFromLocal: (cityCode?: string, lang?: string) => void;
};
const RecentlyViewedListWrapper = ({
	elementsToShow = 4,
	elementsToSlide = 2,
	onScrollIntoView,
	title,
	currentCityCode,
	fetchProductIdListFromLocal,
	productIds,
	lang,
}: Props) => {
	const recentlyViewedCarouselRef = useRef<TSwiperRefActions>(null);
	const { showLeftArrow, showRightArrow, onSlideChanged } = useSwiperArrows();

	useEffect(() => {
		fetchProductIdListFromLocal(currentCityCode, lang);
	}, [currentCityCode, lang, fetchProductIdListFromLocal]);

	if (!productIds.length) return null;

	const swiperSettings: Omit<TSwiperProps, 'children'> = {
		slidesToShow: elementsToShow,
		slidesToScrollBy: elementsToSlide,
		swiperRef: recentlyViewedCarouselRef,
		nextPrevControls: 'hide',
		onSlideChanged,
	};

	const handlePrev = () => {
		recentlyViewedCarouselRef.current?.prevSlide();
		trackSwiperArrowClick('backward');
	};

	const handleNext = () => {
		recentlyViewedCarouselRef.current?.nextSlide();
		trackSwiperArrowClick('forward');
	};

	const trackSwiperArrowClick = (direction: 'forward' | 'backward') => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.CAROUSEL.CHEVRON_CLICKED,
			[ANALYTICS_PROPERTIES.DIRECTION]:
				capitalizeOnlyFirstLetter(direction),
			[ANALYTICS_PROPERTIES.SECTION]: title,
		});
	};

	return (
		<section className='core-column-container'>
			<FeedSectionHeader
				onScrollIntoView={onScrollIntoView}
				title={title}
			>
				<Conditional if={productIds.length > elementsToShow}>
					<NavigationButtons
						showLeftArrow={showLeftArrow}
						showRightArrow={showRightArrow}
						prevSlide={handlePrev}
						nextSlide={handleNext}
					/>
				</Conditional>
			</FeedSectionHeader>
			<ListWrapper>
				<Swiper {...swiperSettings}>
					{productIds.map((id, index) => {
						const cardClassNames = classNames({
							'product-card-v2': id,
							'dummy-product-card': !id,
							'product-card-on-visible': true,
						});

						return id ? (
							<ProductCardComponent
								key={id}
								cardIndex={index}
								id={id}
								className={cardClassNames}
								isRecentlyViewed
								parentIdentifier={createSanitisedIdentifer(
									title,
								)}
							/>
						) : (
							<DummyProductCard
								className='product-card-wrap'
								key={index}
							/>
						);
					})}
				</Swiper>
			</ListWrapper>
		</section>
	);
};

const mapStateToProps = (state: any) => {
	const cityCode = getCurrentCityCode(state);

	let productIds = getRecentlyViewedProductIds(
		state,
		cityCode || GLOBAL_CITY_CODE,
	);
	productIds =
		productIds
			?.map((id: any) => getProductCard(state, id))
			?.map((p: any) => p?.id) ?? [];

	return {
		productIds,
		currentCityCode: cityCode || GLOBAL_CITY_CODE,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	fetchProductIdListFromLocal(cityCode = GLOBAL_CITY_CODE, lang = 'en') {
		dispatch(
			fetchRecentProductIdList({
				cityCode,
				lang,
			}),
		);
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RecentlyViewedListWrapper);
